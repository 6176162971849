import Eventable from '../Eventable';
import ApplePayHtml from './ApplePay.html';
import ApplePayCSS from './ApplePay.css';
import ApplePayRequest from './ApplePayRequest';
import TokenRepository from '../TokenRepository';

class ApplePayField extends Eventable {
  constructor(options) {
    super();

    this.applePayRequest = ApplePayRequest.create({
      country: options.country,
      price: options.price,
      currency: options.currency,
      cardBrands: options.cardBrands,
      shippingMethods: options.shippingMethods,
      shippingType: options.shippingType,
      requiredBillingContactFields: options.requiredBillingContactFields,
      requiredShippingContactFields: options.requiredShippingContactFields,
      contactFields: options.contactFields,
      contactFieldsMappedTo: options.contactFieldsMappedTo,
      lineItems: options.lineItems,
      totalLabel: options.totalLabel,
      totalType: options.totalType,
      tokenizationKey: options.tokenizationKey,
      cartCorrelationId: options.cartCorrelationId,
      domainName: options.domainName,
      isRecurringTransaction: options.isRecurringTransaction,
      recurringPaymentDescription: options.recurringPaymentDescription,
      recurringBillingAgreement: options.recurringBillingAgreement,
      recurringRegularBilling: options.recurringRegularBilling,
      recurringManagementUrl: options.recurringManagementUrl,
      recurringTokenNotificationUrl: options.recurringTokenNotificationUrl,
      recurringMismatchCallback: options.recurringMismatchCallback,
      applePayVersion: options.applePayVersion,
    });
    this.styles = {
      'button-type': 'black',
      height: '30px',
      'border-radius': '4px',
    };
    this.type = options.type;
    this.boundHandleOnClick = this.handleOnClick.bind(this);
  }

  style(options) {
    this.styles = options;
  }

  // eslint-disable-next-line class-methods-use-this
  unmount(selector) {
    document.querySelectorAll(selector).forEach(container => {
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
    });
  }

  mount(selector) {
    document.querySelectorAll(selector).forEach(container => {
      const parser = new window.DOMParser();
      const button = parser
        .parseFromString(ApplePayHtml, 'text/html')
        .querySelector('apple-pay-button');
      button.addEventListener('click', e => {
        this.invokeCallbacks('click', e);
      });

      button.setAttribute('buttonstyle', this.styles['button-style']);
      button.setAttribute('type', this.type);
      button.style.setProperty('--apple-pay-button-width', '100%');
      button.style.setProperty('--apple-pay-button-height', this.styles.height || '30px');
      button.style.setProperty(
        '--apple-pay-button-border-radius',
        this.styles['border-radius'] || '4px',
      );

      const styles = document.createElement('style');
      styles.type = 'text/css';
      styles.innerText = ApplePayCSS;

      if (container instanceof window.Node) {
        container.appendChild(styles);
        container.appendChild(button);
      }
    });

    this.on('click', this.boundHandleOnClick);
  }

  handleOnClick() {
    this.applePayRequest
      .capturePayment()
      .then(applePayResponse => {
        this.invokeCallbacks('complete', {
          applePayResponse,
        });
      })
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  }

  async updateToken(token, tokenizationKey, cartCorrelationId) {
    const {
      applePayResponse: { payment },
    } = this.applePayRequest;

    if (payment === null) {
      return {
        result: 'error',
        error:
          'User has not finished interacting with the digital wallet.' +
          ' Tokenizing the a digital wallet must occur after user has finished with it. ',
      };
    }

    const fields = [];

    const addField = (elementId, value) => {
      fields.push({
        elementId,
        value,
      });
    };

    addField('applepay_payment_data', payment.token.paymentData);

    if (this.applePayRequest.recurringRegularBilling instanceof Object) {
      const recurringBilling = this.applePayRequest.recurringRegularBilling;

      if (typeof recurringBilling.amount === 'string') {
        addField('amount', recurringBilling.amount);
      }

      if (typeof recurringBilling.label === 'string') {
        addField('label', recurringBilling.label);
      }

      if (typeof recurringBilling.paymentTiming === 'string') {
        addField('paymentTiming', recurringBilling.paymentTiming);
      }

      if (typeof recurringBilling.recurringPaymentEndDate === 'string') {
        addField('recurringPaymentEndDate', recurringBilling.recurringPaymentEndDate);
      }

      if (typeof recurringBilling.recurringPaymentIntervalCount === 'number') {
        addField('recurringPaymentIntervalCount', recurringBilling.recurringPaymentIntervalCount);
      }

      if (typeof recurringBilling.recurringPaymentIntervalUnit === 'string') {
        addField('recurringPaymentIntervalUnit', recurringBilling.recurringPaymentIntervalUnit);
      }

      if (typeof recurringBilling.recurringPaymentStartDate === 'string') {
        addField('recurringPaymentStartDate', recurringBilling.recurringPaymentStartDate);
      }
    }

    if (payment.billingContact instanceof Object) {
      if (payment.billingContact.addressLines instanceof Array) {
        addField('address1', payment.billingContact.addressLines[0]);
        addField('address2', payment.billingContact.addressLines[1]);
      }

      if (typeof payment.billingContact.administrativeArea === 'string') {
        addField('state', payment.billingContact.administrativeArea);
      }

      if (typeof payment.billingContact.countryCode === 'string') {
        addField('country', payment.billingContact.countryCode);
      }

      if (typeof payment.billingContact.familyName === 'string') {
        addField('last_name', payment.billingContact.familyName);
      }

      if (typeof payment.billingContact.givenName === 'string') {
        addField('first_name', payment.billingContact.givenName);
      }

      if (typeof payment.billingContact.locality === 'string') {
        addField('city', payment.billingContact.locality);
      }

      if (typeof payment.billingContact.postalCode === 'string') {
        addField('zip', payment.billingContact.postalCode);
      }
    }

    if (payment.shippingContact instanceof Object) {
      if (payment.shippingContact.addressLines instanceof Array) {
        addField('shipping_address_1', payment.shippingContact.addressLines[0]);
        addField('shipping_address_2', payment.shippingContact.addressLines[1]);
      }

      if (typeof payment.shippingContact.administrativeArea === 'string') {
        addField('shipping_state', payment.shippingContact.administrativeArea);
      }

      if (typeof payment.shippingContact.countryCode === 'string') {
        addField('shipping_country', payment.shippingContact.countryCode);
      }

      if (typeof payment.shippingContact.familyName === 'string') {
        addField('shipping_lastname', payment.shippingContact.familyName);
      }

      if (typeof payment.shippingContact.givenName === 'string') {
        addField('shipping_firstname', payment.shippingContact.givenName);
      }

      if (typeof payment.shippingContact.locality === 'string') {
        addField('shipping_city', payment.shippingContact.locality);
      }

      if (typeof payment.shippingContact.postalCode === 'string') {
        addField('shipping_zip', payment.shippingContact.postalCode);
      }

      if (typeof payment.shippingContact.phoneNumber === 'string') {
        if (this.applePayRequest.contactFieldsMappedTo === 'billing') {
          addField('phone', payment.shippingContact.phoneNumber);
        }

        if (this.applePayRequest.contactFieldsMappedTo === 'shipping') {
          addField('shipping_phone', payment.shippingContact.phoneNumber);
        }
      }

      if (typeof payment.shippingContact.emailAddress === 'string') {
        if (this.applePayRequest.contactFieldsMappedTo === 'billing') {
          addField('email', payment.shippingContact.emailAddress);
        }

        if (this.applePayRequest.contactFieldsMappedTo === 'shipping') {
          addField('shipping_email', payment.shippingContact.emailAddress);
        }
      }
    }

    try {
      await TokenRepository.updateMultipartToken(token, tokenizationKey, fields, cartCorrelationId);
      return {
        result: 'success',
      };
    } catch (e) {
      return {
        result: 'error',
      };
    }
  }

  static isNumeric(numberOrNot) {
    return /^-?\d+$/.test(numberOrNot);
  }

  /**
   * This was written off an assumption that we will be provided something like "Visa 1111"
   */
  static getCardDetails(cardDescription) {
    if (typeof cardDescription !== 'string') {
      return null;
    }

    const lastFourOrNot = cardDescription.split(' ').pop();
    if (!ApplePayField.isNumeric(lastFourOrNot)) {
      return null;
    }

    return lastFourOrNot;
  }

  /**
   * Organizes Collect.js's "wallet" response data.
   *
   * Example paymentInfo:
   *
   * {
   *   "billingContact": {
   *     "addressLines": [
   *       "123 Fake Ct",
   *       "APT 2L"
   *     ],
   *     "administrativeArea": "NY",
   *     "country": "United States",
   *     "countryCode": "US",
   *     "familyName": "Doe",
   *     "givenName": "John",
   *     "locality": "New York",
   *     "phoneticFamilyName": "",
   *     "phoneticGivenName": "",
   *     "postalCode": "12345",
   *     "subAdministrativeArea": "",
   *     "subLocality": ""
   *   },
   *   "shippingContact": {
   *     "addressLines": [
   *       "123 Fake Ct",
   *       "APT 2L"
   *     ],
   *     "administrativeArea": "NY",
   *     "country": "United States",
   *     "countryCode": "US",
   *     "emailAddress": "test@example.com",
   *     "familyName": "Doe",
   *     "givenName": "John",
   *     "locality": "New York",
   *     "phoneNumber": "8001234567",
   *     "phoneticFamilyName": "",
   *     "phoneticGivenName": "",
   *     "postalCode": "12345",
   *     "subAdministrativeArea": "",
   *     "subLocality": ""
   *   },
   *   "token": {
   *     "paymentData": {
   *       "data": "...",
   *       "signature": "...",
   *       "header": {
   *         "publicKeyHash": "JiWQzJWfBOkEEjlvjZVUVpYMCHHu8API9famvlY90J8=",
   *         "ephemeralPublicKey": "...",
   *         "transactionId": "9410b486f6b04aa35b492a4ec13c85e500894b7cf7b08f7fd75f522d1cddcab6"
   *       },
   *       "version": "EC_v1"
   *     },
   *     "paymentMethod": {
   *       "displayName": "Visa 1234",
   *       "network": "Visa",
   *       "type": "credit"
   *     },
   *     "transactionIdentifier": "9410B486F6B14AF355492A4EC13C85E500894B7CF9B08F7FD65F522D1CDDCAB6"
   *   }
   * }
   */
  static getWalletDataFromPaymentData(event) {
    const billingContact = event?.applePayResponse?.payment?.billingContact;
    const shippingContact = event?.applePayResponse?.payment?.shippingContact;
    const cardDisplayName = event?.applePayResponse?.payment?.token?.paymentMethod?.displayName;
    const cardNetwork = event?.applePayResponse?.payment?.token?.paymentMethod?.network;

    return {
      cardDetails: ApplePayField.getCardDetails(cardDisplayName) || null,
      cardNetwork: cardNetwork.toLowerCase() || null,
      email: shippingContact?.emailAddress || null,
      billingInfo: {
        address1: billingContact?.addressLines?.[0] || null,
        address2: billingContact?.addressLines?.[1] || null,
        firstName: billingContact?.givenName || null,
        lastName: billingContact?.familyName || null,
        postalCode: billingContact?.postalCode || null,
        city: billingContact?.locality || null,
        state: billingContact?.administrativeArea || null,
        country: billingContact?.countryCode || null,
        phone: null,
      },
      shippingInfo: {
        method: event?.applePayResponse?.shippingMethod?.identifier || null,
        address1: shippingContact?.addressLines?.[0] || null,
        address2: shippingContact?.addressLines?.[1] || null,
        firstName: shippingContact?.givenName || null,
        lastName: shippingContact?.familyName || null,
        postalCode: shippingContact?.postalCode || null,
        city: shippingContact?.locality || null,
        state: shippingContact?.administrativeArea || null,
        country: shippingContact?.countryCode || null,
        phone: shippingContact?.phoneNumber || null,
      },
    };
  }
}

ApplePayField.FIELD_TYPE = 'applePay';

export default ApplePayField;
